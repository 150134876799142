import React from "react";
import Helmet from "react-helmet";
import Link from "gatsby-link";
import BigHeader from "../components/Header/BigHeader";
import { graphql } from "gatsby";
import Layout from "../layout";
import PostListing from "../components/PostListing/PostListing";
import HomeBio from "../components/HomeBio/HomeBio";
import SEO from "../components/SEO/SEO";
import config from "../../data/SiteConfig";
import typography from "../utils/typography"
const rhythm = typography.rhythm
const scale = typography.scale

class Index extends React.Component {
  render() {
      let bio

      bio =(
        <div
        style={{
          fontSize:'16px',
          fontFamily: 'inconsolata',
          marginBottom: rhythm(1.5),
        }}
      >
        {/* Hi, I am <Link to='/about/'><strong>Rahul Patil</strong></Link> and these are my thoughts about Cloud, Bots, AI and random awesome tech.. */}
        <HomeBio />
      </div>
      )
    const postEdges = this.props.data.allMarkdownRemark.edges;
    return (
      <Layout>
        <BigHeader />
        <div> {bio} </div> 
        <div className="index-container">
          <Helmet title={config.siteTitle} />
          <SEO />
          <PostListing postEdges={postEdges} />
        </div>
      </Layout>
    );
  }
}

export default Index;

/* eslint no-undef: "off" */
export const pageQuery = graphql`
  query IndexQuery {
    allMarkdownRemark(
      limit: 2000
      sort: { fields: [fields___date], order: DESC }
    ) {
      edges {
        node {
          fields {
            slug
            date
          }
          excerpt
          timeToRead
          frontmatter {
            title
            tags
            date
          }
        }
      }
    }
  }
`;
