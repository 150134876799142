import React, { Component } from "react";
import { Link } from "gatsby";
import siteConfig from "../../../data/SiteConfig";
import AnimatedPhrases from "../AnimatedPhrases/AnimatedPhrases";
import "./HomeBio.css"

class HomeBio extends Component {
    render() {
        const { config } = this.props;
        const url = siteConfig.siteRss;
        const { blogSkills, headline } = siteConfig;

        return (
            <section className="cd-intro">
                <AnimatedPhrases headline={headline} phrases={blogSkills} />
            </section>
        );
    }
}

export default HomeBio;
